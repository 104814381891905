@use 'node_modules/material-icons/iconfont/material-icons.css';
@use 'mat-theme';
@use 'bootstrap-default';
@use 'fonts';
// variables

$white: #fefefe;
$highlight-color: #fe5722;
$background_blue: #3a526a;
$background_blue_tint: #496685;

$background-white: #fefefe;
$orange-border: #fe4f22;
$grey-white: rgb(248, 248, 248);
$greygrey-white: #e1e4e8;
$grey: #dee2e6;
$hover-day: #EDEDED;
$table-border: #DFDFDF;
$light-blue: #B4E6FB;
$range-blue: #E0F5FE;
$weekEnd: rgb(249, 249, 249);
$opacityColor: #D9D9DA;
$black: #000000;
/* Add padding for the nav bar */
body {
	font-family: Arial, serif;
	padding-top: 4rem;
	margin-top: 2rem;
	letter-spacing: normal;
	margin-left: 2rem;
	margin-right: 2rem;
	background: linear-gradient(to bottom, $background_blue 20%, $background_blue_tint 100%) no-repeat fixed;
	font-size: 14px;
}

/* sets minimum width of mat-card */
.mat-card-single {
	min-width: 400px;
	margin-bottom: 10em;
	max-width: 1700px;
}

mat-card {
	background-color: $background-white !important;
}

/* sets the used hih */
.highlight {
	color: $highlight-color;
}


/* Style debug info in alerts */
.alert-pre {
	word-wrap: break-word;
	word-break: break-all;
	white-space: pre-wrap;
}

.justify-content-center-old {
	display: flex;
	justify-content: center;
}

.input-width100 {
	width: 100%;
}

.width50 {
	width: 50%;
}

.capitalize {
	text-transform: capitalize;
}

.move-cursor {
	cursor: move;
}

.margin5 {
	margin: 1em;
}

.marginY2px {
	margin-top: 2px;
	margin-bottom: 2px;
}

.marginY1em {
	//margin-top: 1em;
	margin-bottom: 1em;
}

.marginX2px {
	margin-left: 2px;
	margin-right: 2px;
}

.marginR2px {
	margin-right: 2px;
}

.marginX1em {
	margin-left: 1em;
	margin-right: 1em;
}

.mat-button-line-height-fix {
	line-height: 36px;
}

.strong-font {
	font-weight: bold;
}

.flex-container-horizontal {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: baseline;
}

.flex-between {
	display: flex;
	justify-content: space-between;
}

.inline-display {
	display: inline;
}

// css Flexbox

.css-container-flex {
	display: flex;
}

.css-justify-content-center {
	justify-content: center;
}

.css-align-items-center {
	align-items: center;
}

.css-flex-wrap {
	flex-wrap: wrap;
}


.flex {
	display: flex;
}

.space-between {
	justify-content: space-between;
}


.flex-wrap-center-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.flex-wrap-space-between-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.material-icons.arrow {
	margin: 0;
	width: 1em;
	height: 1em;
}
.material-icons.md-24 {
	font-size: 24px;
	width: auto;
	height: auto;
}
.material-icons.md-30 {
	font-size: 30px;
	width: 30px;
	height: 30px;
}

.material-icons.md-40 {
	font-size: 40px;
	width: auto;
	height: auto;
}

.mat-mdc-card-avatar{
	margin-bottom: 0;
}

.mat-mdc-card-content:last-child{
	padding-bottom: 0;
}

.mdc-button{
	min-width: 0;
}

mat-card-title{
	padding: 0;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix
{
	padding: 8px 0 !important;
}

.mat-mdc-form-field-infix {
	min-height: 0 !important;
}


.mdc-floating-label {
	overflow: visible !important;
}

.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-outline .mdc-notched-outline__notch{
	width: auto !important;
	max-width: none !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label{
	top: 22px  !important;
}

.mdc-notched-outline--notched .mdc-notched-outline__notch{
	padding-right: 0 !important;
}
