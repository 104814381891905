:root {
	--bs-blue: #0d6efd;
	--bs-indigo: #6610f2;
	--bs-purple: #6f42c1;
	--bs-pink: #d63384;
	--bs-red: #dc3545;
	--bs-orange: #fd7e14;
	--bs-yellow: #ffc107;
	--bs-green: #198754;
	--bs-teal: #20c997;
	--bs-cyan: #0dcaf0;
	--bs-white: #fff;
	--bs-gray: #6c757d;
	--bs-gray-dark: #343a40;
	--bs-primary: #0d6efd;
	--bs-secondary: #6c757d;
	--bs-success: #198754;
	--bs-info: #0dcaf0;
	--bs-warning: #ffc107;
	--bs-danger: #dc3545;
	--bs-light: #f8f9fa;
	--bs-dark: #212529;
	--bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	--bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))
}

*, ::after, ::before {
	box-sizing: border-box
}

@media (prefers-reduced-motion: no-preference) {
	:root {
		scroll-behavior: smooth
	}
}

body {
	font-family: var(--bs-font-sans-serif);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent
}

hr {
	margin: 1rem 0;
	color: inherit;
	background-color: currentColor;
	border: 0;
	opacity: .25
}

hr:not([size]) {
	height: 1px
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: .5rem;
	font-weight: 500;
	line-height: 1.2
}

.h1, h1 {
	font-size: calc(1.375rem + 1.5vw)
}

@media (min-width: 1200px) {
	.h1, h1 {
		font-size: 2.5rem
	}
}

.h2, h2 {
	font-size: calc(1.325rem + .9vw)
}

@media (min-width: 1200px) {
	.h2, h2 {
		font-size: 2rem
	}
}

.h3, h3 {
	font-size: calc(1.3rem + .6vw)
}

@media (min-width: 1200px) {
	.h3, h3 {
		font-size: 1.75rem
	}
}

.h4, h4 {
	font-size: calc(1.275rem + .3vw)
}

@media (min-width: 1200px) {
	.h4, h4 {
		font-size: 1.5rem
	}
}

.h5, h5 {
	font-size: 1.25rem
}

.h6, h6 {
	font-size: 1rem
}

p {
	margin-top: 0;
	margin-bottom: 1rem
}

abbr[data-bs-original-title], abbr[title] {
	text-decoration: underline dotted;
	cursor: help;
	text-decoration-skip-ink: none
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit
}

ol, ul {
	padding-left: 2rem
}

dl, ol, ul {
	margin-top: 0;
	margin-bottom: 1rem
}

ol ol, ol ul, ul ol, ul ul {
	margin-bottom: 0
}

dt {
	font-weight: 700
}

dd {
	margin-bottom: .5rem;
	margin-left: 0
}

blockquote {
	margin: 0 0 1rem
}

b, strong {
	font-weight: bolder
}

.small, small {
	font-size: .875em
}

.mark, mark {
	padding: .2em;
	background-color: #fcf8e3
}

sub, sup {
	position: relative;
	font-size: .75em;
	line-height: 0;
	vertical-align: baseline
}

sub {
	bottom: -.25em
}

sup {
	top: -.5em
}

a {
	color: #0d6efd;
	text-decoration: underline
}

a:hover {
	color: #0a58ca
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
	color: inherit;
	text-decoration: none
}

code, kbd, pre, samp {
	font-family: var(--bs-font-monospace);
	font-size: 1em;
	direction: ltr;
	unicode-bidi: bidi-override
}

pre {
	display: block;
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	font-size: .875em
}

pre code {
	font-size: inherit;
	color: inherit;
	word-break: normal
}

code {
	font-size: .875em;
	color: #d63384;
	word-wrap: break-word
}

a > code {
	color: inherit
}

kbd {
	padding: .2rem .4rem;
	font-size: .875em;
	color: #fff;
	background-color: #212529;
	border-radius: .2rem
}

kbd kbd {
	padding: 0;
	font-size: 1em;
	font-weight: 700
}

figure {
	margin: 0 0 1rem
}

img, svg {
	vertical-align: middle
}

table {
	caption-side: bottom;
	border-collapse: collapse
}

caption {
	padding-top: .5rem;
	padding-bottom: .5rem;
	color: #6c757d;
	text-align: left
}

th {
	text-align: inherit;
	text-align: -webkit-match-parent
}

tbody, td, tfoot, th, thead, tr {
	border-color: inherit;
	border-style: solid;
	border-width: 0
}

label {
	display: inline-block
}

button {
	border-radius: 0
}

button:focus:not(:focus-visible) {
	outline: 0
}

button, input, optgroup, select, textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit
}

button, select {
	text-transform: none
}

[role=button] {
	cursor: pointer
}

select {
	word-wrap: normal
}

select:disabled {
	opacity: 1
}

[list]::-webkit-calendar-picker-indicator {
	display: none
}

[type=button], [type=reset], [type=submit], button {
	-webkit-appearance: button
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
	cursor: pointer
}

::-moz-focus-inner {
	padding: 0;
	border-style: none
}

textarea {
	resize: vertical
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0
}

legend {
	float: left;
	width: 100%;
	padding: 0;
	margin-bottom: .5rem;
	font-size: calc(1.275rem + .3vw);
	line-height: inherit
}

@media (min-width: 1200px) {
	legend {
		font-size: 1.5rem
	}
}

legend + * {
	clear: left
}


[type=search] {
	outline-offset: -2px;
	-webkit-appearance: textfield
}


output {
	display: inline-block
}

iframe {
	border: 0
}

summary {
	display: list-item;
	cursor: pointer
}

progress {
	vertical-align: baseline
}

[hidden] {
	display: none !important
}
